import "./Login.css";

import * as React from "react";

import { Col, Row } from "antd";

import { DownCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IS_MOBILE } from "./helpers/constants";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Typography } from "antd";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { uiConfig } from "./firebase/Authentication";

const { Title } = Typography;

type LoginProps = { fbAuth: any };

const headlineOrder = IS_MOBILE ? 1 : 2;
const firebaseOrder = IS_MOBILE ? 2 : 1;

const Login: React.FC<LoginProps> = ({ fbAuth }) => {
  return (
    <>
      <div className="login-container">
        <div className="login-card">
          <Row justify="space-between" align="bottom">
            <Col sm={24} lg={12} order={headlineOrder}>
              <div className="headline">
                <Title level={2}>
                  The first
                  <br />
                  text-based
                  <br />
                  habit tracker.
                </Title>
                <Title level={1}>DAYBYDAY.</Title>
                <div style={{ width: "50%", float: "right" }}>
                  <p>
                    Change your behavior and know yourself. All with a nudge.
                    Track your life with daily SMS chats that move you in the
                    right direction.
                  </p>
                  <p>
                    <DownCircleOutlined style={{ fontSize: "3rem" }} />
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={24} lg={12} order={firebaseOrder}>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fbAuth} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="section explainer">
        <div className="container">
          <div className="row">
            <div className="title-area">
              <h2>How It Works</h2>
              <div className="separator separator-danger">✻</div>
              <p className="description">
                "He said for each day that I do my task of writing, I get to put
                a big red X over that day. "After a few days you'll have a
                chain. Just keep at it and the chain will grow longer every day.
                You'll like seeing that chain, especially when you get a few
                weeks under your belt. Your only job next is to not break the
                chain." -{" "}
                <a
                  href="https://lifehacker.com/jerry-seinfelds-productivity-secret-281626"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Jerry Seinfeld's Productivity Secret
                </a>
              </p>
              <p className="description">
                Science shows that tracking certain attributes, like weight,
                number of pages read, or how much TV watched, should be done
                daily to encourage good behavior. But tracking your habits by
                paper can be cumbersome to manage and easy to lose, and apps
                require the hard work of forming a new habit of using them!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="info-icon">
                <div className="icon text-danger">
                  <i className="pe-7s-graph1"></i>
                </div>
                <h3>Choose What To Track</h3>
                <p className="description">
                  We make our design perfect for you. Our adjustment turn our
                  clothes into your clothes.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info-icon">
                <div className="icon text-danger">
                  <i className="pe-7s-note2"></i>
                </div>
                <h3>Reply To Our Text</h3>
                <p className="description">
                  We create a persona regarding the multiple wardrobe
                  accessories that we provide..
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info-icon">
                <div className="icon text-danger">
                  <i className="pe-7s-music"></i>
                </div>
                <h3>Make Progress</h3>
                <p className="description">
                  We like to present the world with our work, so we make sure we
                  spread the word regarding our clothes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section feature" id="feature">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>App Features</h2>
                <p>
                  <h5>
                    <strong>Before DayByDay:</strong> You've tried several habit
                    tracker apps, but you forget to use them. They sit around on
                    your phone, making it easy to forget, and hard to change.{" "}
                    <strong>After DayByDay:</strong> Every day, you receive a
                    gentle reminder and have a chat with DayByDay. Your habits
                    are recorded instantly and analyzed. You get a better sense
                    of yourself and find it easier to be better.
                  </h5>
                </p>
              </div>
            </div>
          </div>
          <div className="row bg-elipse">
            <div className="col-lg-4 align-self-center text-center text-lg-right">
              <div className="feature-item">
                <div className="icon">
                  <i className="tf-circle-compass"></i>
                </div>

                <div className="content">
                  <h5>Metrics Motivate</h5>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising
                  </p>
                </div>
              </div>

              <div className="feature-item">
                <div className="icon">
                  <i className="tf-tools-2"></i>
                </div>

                <div className="content">
                  <h5>No App to Download</h5>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="feature-item mb-0">
                <div className="icon">
                  <i className="tf-chat"></i>
                </div>

                <div className="content">
                  <h5>Intuitive Web Interface</h5>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising
                  </p>
                </div>
              </div>
              <div className="app-screen">
                <video
                  width="100%"
                  controls={false}
                  autoPlay
                  muted
                  loop
                  playsinline
                >
                  <source src="/daybyday.mp4" type="video/mp4" />
                </video>
              </div>

              <div className="feature-item">
                <div className="icon">
                  <i className="tf-hourglass"></i>
                </div>

                <div className="content">
                  <h5>Analysis of Habits</h5>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-left align-self-center">
              <div className="feature-item">
                <div className="icon">
                  <i className="tf-mobile"></i>
                </div>

                <div className="content">
                  <h5>At Your Convenience</h5>
                  <p>Keep Track At Any Time</p>
                </div>
              </div>

              <div className="feature-item">
                <div className="icon">
                  <i className="tf-layers"></i>
                </div>

                <div className="content">
                  <h5>Total Privacy and Control</h5>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
