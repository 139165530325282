import * as React from "react";

import Moment from "moment";
import { Select } from "antd";

const { Option } = Select;

/**
 * Fancy ID generator that creates 20-character string identifiers with the following properties:
 *
 * 1. They're based on timestamp so that they sort *after* any existing ids.
 * 2. They contain 72-bits of random data after the timestamp so that IDs won't collide with other clients' IDs.
 * 3. They sort *lexicographically* (so the timestamp is converted to characters that will sort properly).
 * 4. They're monotonically increasing.  Even if you generate more than one in the same timestamp, the
 *    latter ones will sort after the former ones.  We do this by using the previous random bits
 *    but "incrementing" them by 1 (only in the case of a timestamp collision).
 */
export const generatePushID = (function () {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars: any[] = [];

  return function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
})();

export function timeOptions() {
  let to: any[] = [];
  for (let i = 0; i < 24; i++) {
    to.push(
      <Option value={i}>
        {Moment.utc(i, "HH").tz(Moment.tz.guess()).format("hh:mm A zz")}
      </Option>
    );
  }
  return to;
}

export function formatHabitsForDisplay(
  habits: { [key: string]: any },
  habitEntries: { [heKey: string]: any }
) {
  Object.keys(habits).forEach((habitKey) => {
    if (habitEntries) {
      for (let [habitId, entriesForHabit] of Object.entries(habitEntries)) {
        for (let [entryId, entry] of Object.entries(entriesForHabit)) {
          habitEntries[habitId][entryId]["id"] = entryId;
        }
      }

      // Need to cover when no habitEntries yet
      habits[habitKey]["habitEntries"] = habitEntries[habitKey] || {};
    } else {
      habits[habitKey]["habitEntries"] = {};
    }

    // HACK for IDs
    habits[habitKey]["id"] = habitKey;
  });
  console.log("-----habits", habits);
  return habits;
}

export const generateNewHabitEntry = async (
  updateData: {
    habitKey: string;
    habit: any;
    habitEntry: any;
    newResponse: any;
    userId: string;
  },
  dbRef: any
) => {
  let { habitKey, habit, habitEntry, newResponse, userId } = updateData;

  function betterThanLast(reply: string, prevHabitEntryMsg: any) {
    if (parseInt(reply) <= parseInt(prevHabitEntryMsg)) {
      return "success";
    } else {
      return "failure";
    }
  }

  function containsKeywords(reply: string, currHabit: any) {
    const successKeywords: string[] = [
      "yes",
      "ya",
      "yah",
      "yeah",
      "yea",
      "confirm",
      "yep",
      "yup",
      "you bet",
      "sure",
      "certainly",
      "ok",
      "okay",
      "of course",
      "mhmm",
    ];

    if (
      successKeywords.some((keyword) => reply.toLowerCase().includes(keyword))
    ) {
      return "success";
    } else {
      return "failure";
    }
  }

  // If not updating, create one
  if (!habitEntry) {
    habitEntry = {
      userId: userId,
      habitId: habitKey,
      date: Moment().unix(),
      message: newResponse,
    };
  }

  let replyHabitResult: string = "";
  let previousHabitEntrySnap: any;
  let previousHabitEntry: any = {};

  if (habit.type === "metric") {
    if (habitEntry && habitEntry.previousHabitEntryId) {
      // this is the case where it's an update
      const previousHabitEntrySnap = await dbRef
        .child("habitEntries")
        .child(userId)
        .child(habitKey)
        .child(habitEntry.previousHabitEntryId)
        .once("value");

      previousHabitEntry = await previousHabitEntrySnap.val();
    } else {
      // this is on creation. just get the last one
      const previousHabitEntrySnap = await dbRef
        .child("habitEntries")
        .child(userId)
        .child(habitKey)
        .orderByChild("date")
        .limitToLast(1)
        .once("value");

      console.log("previousHabitEntrySnap", previousHabitEntrySnap);

      previousHabitEntry = await previousHabitEntrySnap.val();

      console.log("previousHabitEntry", previousHabitEntry);
    }

    if (previousHabitEntry) {
      const values: any = Object.values(previousHabitEntry);
      const previousEntryMsg: string = values[0].message;
      replyHabitResult = betterThanLast(
        newResponse.trim(),
        previousEntryMsg.trim()
      );
    } else {
      // for first ever habit entry for habit
      replyHabitResult = "success";
    }
  } else if (habit.type === "question") {
    // any response to a question is a success
    replyHabitResult = "success";
  } else {
    replyHabitResult = containsKeywords(newResponse, habit);
  }

  return Object.assign(habitEntry, {
    message: newResponse,
    result: replyHabitResult,
    previousHabitEntryId: previousHabitEntrySnap
      ? previousHabitEntrySnap.key
      : null,
    id: habitEntry.id ? habitEntry.id : generatePushID(),
  });
};
