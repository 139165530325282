import * as firebase from "firebase";
import * as firebaseui from "firebaseui";

/**
 * The Database class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class Authentication {
  public static instance: Authentication;
  public fb: firebase.auth.Auth;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    const config = {
      apiKey: "AIzaSyCXH_McsBh6UnWY7odYbHmoIQLdiquFhbo",
      authDomain: "friends-with-jake.firebaseapp.com",
      databaseURL: "https://friends-with-jake.firebaseio.com",
      projectId: "friends-with-jake",
      storageBucket: "friends-with-jake.appspot.com",
      messagingSenderId: "35550268808",
      appId: "1:35550268808:web:a611ff384605557dca7e3d",
      measurementId: "G-VRNW2Z87YS",
    };

    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }

    this.fb = firebase.auth();
  }

  /**
   * The static method that controls the access to the singleton Authentication instance.
   *
   * This implementation let you subclass the Authentication class while keeping
   * just one instance of each subclass around.
   */
  public static getAuthentication(): Authentication {
    if (!Authentication.instance) {
      Authentication.instance = new Authentication();
    }

    return Authentication.instance;
  }
}

export const uiConfig: firebaseui.auth.Config = {
  callbacks: {
    signInFailure: function (error) {
      // Some unrecoverable error occurred during sign-in.
      // Return a promise when error handling is completed and FirebaseUI
      // will reset, clearing any UI. This commonly occurs for error code
      // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      // occurs. Check below for more details on this.
      return Promise.resolve();
      // return handleUIError(error);
    },
  },
  signInSuccessUrl: "/me",
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // Invisible reCAPTCHA with image challenge and bottom left badge.
      recaptchaParameters: {
        type: "image",
        size: "invisible",
        badge: "bottomleft",
      },
    },
  ],
  tosUrl: "<your-tos-url>",
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign("<your-privacy-policy-url>");
  },
};

export default Authentication;
