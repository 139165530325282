import * as React from "react";

import { Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ACTIONS from "./store/actions";
import Database from "./firebase/Database";
import HabitAnalysis from "./HabitAnalysis";
import HabitDescription from "./HabitDescription";
import HabitRecords from "./HabitRecords";
import Moment from "moment";
import { formatHabitsForDisplay } from "./helpers/utils";

const Habit: React.FC<any> = (props: { user: any }) => {
  const { user } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashId, habitId }: any = useParams();
  const habitsByUser = useSelector((state: any) => state.userHabits);

  // Fetch this user dashboard's habits for correlation numbers
  // if they're not already fetched. Might be able to DRY up
  // with Dashboard.tsx as a mixin.
  React.useEffect(() => {
    const db = Database.getDatabase();

    let habits: any;
    let habitEntries: any;

    if (!habitsByUser.hasOwnProperty(dashId)) {
      habits = db.getUserHabits(dashId);
      habitEntries = db.getHabitEntries(dashId);

      Promise.all([habits, habitEntries]).then((results: [any, any]) => {
        const [habits, habitEntries] = results;
        if (habits) {
          const formattedHabits = formatHabitsForDisplay(habits, habitEntries);

          const obj: any = {};
          obj[dashId] = formattedHabits;

          dispatch(ACTIONS.setHabitEntries(obj));
        } else {
          return history.push("/setup");
        }
      });
    }
  }, [dashId, habitId]);

  const allHabits = habitsByUser[dashId] || {};
  let currentHabit: any = { prompt: "", type: "action", habitEntries: {} };
  let sortedHabitEntries: any = [];
  let groupedHabitEntries: any = {};

  if (habitsByUser[dashId]) {
    const currDashHabits = habitsByUser[dashId];
    currentHabit = currDashHabits[habitId];

    const currHabitEntries = Object.values(currentHabit.habitEntries);

    sortedHabitEntries = currHabitEntries.sort((a: any, b: any) => {
      return b.date - a.date;
    });
  }

  return (
    <Card
      className="dashboard"
      bordered={false}
      style={{ width: "100%", height: "100%" }}
    >
      <Row>
        <Col lg={12} sm={24}>
          <HabitDescription habit={currentHabit} user={user} />
          <HabitAnalysis habit={currentHabit} allHabits={allHabits} />
        </Col>

        <Col lg={12} sm={24}>
          <HabitRecords
            dashId={dashId}
            habitId={habitId}
            sortedHabitEntries={sortedHabitEntries}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Habit;
