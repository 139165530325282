import * as React from "react";

import { Avatar, List, Skeleton } from "antd";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Step, Steps, WithWizard, Wizard } from "react-albus";
import { useDispatch, useSelector } from "react-redux";

import ACTIONS from "./store/actions";
import Database from "./firebase/Database";
import Moment from "moment";
import { Typography } from "antd";
import { timeOptions } from "./helpers/utils";

const { Option } = Select;
const { Title } = Typography;

const Setup: React.FC<any> = (props) => {
  const { user } = props;

  const userHabits = useSelector((state: any) => state.userHabits);
  const dispatch = useDispatch();

  const [habitPrompt, setHabitPrompt] = React.useState("Did you run today?");
  const [habitType, setHabitType] = React.useState("action");
  const [schedule, setSchedule]: [string, Function] = React.useState("0");

  const [form] = Form.useForm();

  let history = useHistory();
  const onWizardCompletion = async (values: any) => {
    if (user) {
      const db = Database.getDatabase();
      await db.addUserSchedule(schedule, user.uid).then(() => {
        dispatch(
          ACTIONS.setSchedules([
            {
              utcHour: schedule,
              display: Moment.utc(schedule, "HH")
                .tz(Moment.tz.guess())
                .format("hh:mm A zz"),
            },
          ])
        );
      });

      const newHabit = {
        prompt: habitPrompt,
        type: habitType,
        active: true,
        createdAt: Moment().unix(),
      };

      await db.createUserHabit(newHabit, user.uid).then((snap: any) => {
        const newUserHabits = Object.assign({}, userHabits);
        if (newUserHabits[user.uid]) {
          newUserHabits[user.uid][snap.key] = newHabit;
        }

        dispatch(ACTIONS.setHabitEntries(newUserHabits));

        return history.push("/me");
      });
    }
  };

  function handleOnSelect(value: any) {
    setSchedule(value);
  }

  const sampleHabits = [
    {
      title: "Did you read 500 pages?",
      avatar:
        "https://p7.hiclipart.com/preview/382/800/847/warren-buffett-berkshire-hathaway-investment-investor-finance-warren-buffet.jpg",
      description:
        "Read 500 pages every day. That's how knowledge works. It builds up, like compound interest. - Warren Buffett",
    },
    {
      title: "Did you spend time with people better than yourself?",
      avatar:
        "https://p7.hiclipart.com/preview/382/800/847/warren-buffett-berkshire-hathaway-investment-investor-finance-warren-buffet.jpg",
      description:
        "'It's better to hang out with people better than you. Pick out associates whose behavior is better than yours and you'll drift in that direction.' - Warren Buffett",
    },
    {
      title: "Did you perform some deep work?",
      avatar:
        "https://pbs.twimg.com/profile_images/988775660163252226/XpgonN0X_400x400.jpg",
      description:
        "'20 years ago I would stay in the office for days at a time and not think twice about it.' - Bill Gates",
    },
    {
      title: "Did you speak up during meetings?",
      avatar:
        "https://specials-images.forbesimg.com/imageserve/614677186/960x0.jpg?fit=scale",
      description:
        "'You can be very, very competent, ..but if you’re not willing to speak out.. have the confidence based on your knowledge.. what’s the point?' - Indra Nooyi",
    },
    {
      title: "Did I cut out un-important activities out of my day?",
      avatar:
        "https://pbs.twimg.com/profile_images/988775660163252226/XpgonN0X_400x400.jpg",
      description:
        "'I stopped listening to music and watching TV in my 20s. It sounds extreme, but I did it because I thought they would just distract me from thinking about software.' - Bill Gates",
    },
    {
      title: "Did I meditate twice a day for 20 minutes each?",
      avatar:
        "https://res.cloudinary.com/refind-inc/image/twitter_name/w_200,h_200,c_fill,q_auto,f_auto,d_refind:avatar.png/RayDalio",
      description:
        "'Meditation more than anything in my life was the biggest ingredient of whatever success I’ve had.' – Ray Dalio",
    },
  ];

  return (
    <Card bordered={false} style={{ width: "100%" }}>
      <Row>
        <Col lg={18} sm={24} style={{ margin: "auto" }}>
          <Wizard>
            <Steps>
              <Step
                id="gandalf"
                render={({ next, previous }) => (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h2>
                        Let's add an action, metric, or question to track.
                      </h2>
                      <p>
                        The prompt will be the text message you get as part of
                        your daily feedback session. Examples include{" "}
                        <strong>Did you run today?</strong>,{" "}
                        <strong>Did you avoid smoking today?</strong>, and{" "}
                        <strong>How many calories did I consume today?</strong>.
                      </p>
                    </div>
                    <div
                      className="habit-prompt"
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                      }}
                    >
                      <Form.Item name="habitPrompt">
                        <Input
                          type="text"
                          placeholder={habitPrompt}
                          defaultValue={habitPrompt}
                          value={habitPrompt}
                          onChange={(e) => {
                            setHabitPrompt(e.target.value);
                          }}
                          size="large"
                          maxLength={100}
                        />
                      </Form.Item>
                      <Button
                        type="default"
                        htmlType="submit"
                        shape="round"
                        size={"large"}
                        onClick={next}
                      >
                        Next
                      </Button>
                    </div>
                    <strong>
                      Or choose from examples of habits practiced by influential
                      people.
                    </strong>
                    <Form
                      form={form}
                      name="control-hooks"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <List
                        className="demo-loadmore-list"
                        loading={false}
                        itemLayout="horizontal"
                        dataSource={sampleHabits}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <span
                                key="list-loadmore-edit"
                                onClick={(e) => {
                                  setHabitPrompt(item.title);
                                  next();
                                }}
                              >
                                <strong>Add to Dashboard</strong>
                              </span>,
                            ]}
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={false}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={item.avatar ? item.avatar : ""}
                                    size="large"
                                  />
                                }
                                title={item.title}
                                description={item.description}
                              />
                            </Skeleton>
                          </List.Item>
                        )}
                      />
                    </Form>
                  </>
                )}
              />
              <Step id="dumbledore">
                <div style={{ textAlign: "center" }}>
                  <h2>Great, thanks for the input!</h2>
                  <p>We'll ask you the following prompt on a daily basis:</p>

                  <Title level={1}>{habitPrompt}</Title>
                  <p>
                    Let's keep going! Next we'll define what a{" "}
                    <strong>successful day</strong> looks like for this habit.
                  </p>
                </div>
              </Step>
              <Step id="habit-type">
                <h3>What kind of a habit is this?</h3>
                <p>
                  An <strong>action</strong> is a yes/no check on whether or not
                  you did something. The response should be a confirmation (yes,
                  yeah!) or a negation (no, nope). Example --{" "}
                  <em>Did I eat healthy today?</em> would be the prompt, and{" "}
                  <em>yes</em> or <em>no</em> would be the reply. A successful
                  day is one where you accomplish the action you wanted.
                </p>
                <p>
                  A <strong>metric</strong> is a quantitative measure. The
                  response should only be a number. For example --{" "}
                  <em>How much do I weigh?</em> or{" "}
                  <em>How many pages did I read?</em>. A successful day is one
                  where the number either went lower or higher.
                </p>
                <p>
                  A <strong>question</strong> is something you ask yourself
                  every day. Successful days are any days where you respond. For
                  example -- <em>What am I grateful for?</em>
                </p>

                <div style={{ textAlign: "center" }}>
                  <p>Select the type below:</p>
                  <p>
                    <Select
                      value={habitType}
                      style={{ width: "80%" }}
                      onChange={(val) => {
                        setHabitType(val);
                      }}
                    >
                      <Option value="action">Action</Option>
                      <Option value="metric">Metric</Option>

                      <Option value="question">Question</Option>
                    </Select>
                  </p>
                </div>
              </Step>
              <Step id="ice king">
                <div style={{ textAlign: "center" }}>
                  <h2>
                    Almost done! Finally, what time would you like to chat?
                  </h2>
                </div>

                <p>Choose the time slot of when you'd like to hear from us.</p>
                <p>
                  At that time every day, you'll get a prompt for each habit or
                  metric that you're tracking via SMS.
                </p>
                <p>
                  If at any point you'd like to stop receiving texts from us,
                  simply text back <strong>STOP</strong>.
                </p>
                <p>
                  This time can be changed any point in{" "}
                  <Link to="/configure">Configure</Link> tab.
                </p>
                <div style={{ textAlign: "center" }}>
                  <p>
                    <Select
                      placeholder="Choose a time"
                      style={{ width: "80%" }}
                      onChange={handleOnSelect}
                    >
                      {timeOptions()}
                    </Select>
                  </p>
                </div>
              </Step>
            </Steps>
            <div style={{ textAlign: "center" }}>
              <WithWizard
                render={({ next, previous, step, steps }) => (
                  <div className="example-buttons">
                    {steps.indexOf(step) > 0 && (
                      <Button
                        type="default"
                        htmlType="submit"
                        shape="round"
                        size={"large"}
                        onClick={previous}
                      >
                        Back
                      </Button>
                    )}
                    {steps.indexOf(step) < steps.length - 1 && (
                      <Button
                        type="default"
                        htmlType="submit"
                        shape="round"
                        size={"large"}
                        onClick={next}
                      >
                        Next
                      </Button>
                    )}
                    {steps.indexOf(step) === steps.length - 1 && (
                      <Button
                        type="default"
                        htmlType="submit"
                        shape="round"
                        size={"large"}
                        onClick={onWizardCompletion}
                      >
                        Complete
                      </Button>
                    )}
                  </div>
                )}
              />
            </div>
          </Wizard>
        </Col>
      </Row>
    </Card>
  );
};

export default Setup;
