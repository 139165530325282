import * as React from "react";

import { COL_CELL_SIZE, COL_LABEL_SIZE, IS_MOBILE } from "./helpers/constants";
import {
  CheckCircleTwoTone,
  DeleteTwoTone,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Col, Row, Switch } from "antd";

import { DATE_FORMAT } from "./helpers/constants";
import HabitCell from "./HabitCell";
import { HabitEntry } from "./models";
import Moment from "moment";
import { generatePushID } from "./helpers/utils";
import { useHistory } from "react-router-dom";

function colorHabitCells(habitEntries: any[], updateHabitEntry: any) {
  const habitRow = [];

  let successPtr = 0;
  let failurePtr = 0;
  const successColors = ["#f6ffed", "#d9f7be", "#b7eb8f", "#95de64", "#73d13d"];
  const failureColors = ["#fff1f0", "#ffccc7", "#ffa39e", "#ff7875", "#ff4d4f"];

  for (let i = 0; i < habitEntries.length; i++) {
    const successColorPaleteIdx =
      successPtr >= successColors.length
        ? successColors.length - 1
        : successPtr;
    const failureColorPaleteIdx =
      failurePtr >= failureColors.length
        ? failureColors.length - 1
        : failurePtr;

    if (habitEntries[i] && habitEntries[i].hasOwnProperty("result")) {
      let cellColor: string = "";
      let cellIcon: any = "";

      if (habitEntries[i].result === "success") {
        cellColor = successColors[successColorPaleteIdx];
        if (!IS_MOBILE) {
          cellIcon = (
            <CheckCircleTwoTone
              style={{ fontSize: "1rem" }}
              twoToneColor="#52c41a"
            />
          );
        }
      } else if (habitEntries[i].result === "failure") {
        cellColor = failureColors[failureColorPaleteIdx];
      } else {
        cellColor = "#fafafa";
      }

      habitRow.push(
        <HabitCell
          habitEntry={habitEntries[i]}
          updateHabitEntry={updateHabitEntry}
          cellColor={cellColor}
          cellIcon={cellIcon}
        />
      );
    } else {
      habitRow.push(
        <Col
          className="cell"
          span={COL_CELL_SIZE}
          style={{ backgroundColor: successColors[successColorPaleteIdx] }}
        >
          N/A
        </Col>
      );
    }

    if (i < habitEntries.length - 1) {
      if (
        habitEntries[i].result === "success" &&
        habitEntries[i + 1] &&
        habitEntries[i + 1].result === "success"
      ) {
        // If upcoming is successful, reset failure and increment success
        failurePtr = 0;
        successPtr++;
      } else if (
        habitEntries[i].result === "failure" &&
        habitEntries[i + 1] &&
        habitEntries[i + 1].result === "failure"
      ) {
        // If upcoming is failure, reset success and increment failure
        successPtr = 0;
        failurePtr++;
      } else {
        successPtr = 0;
        failurePtr = 0;
        // Neither success nor failure
      }
    }
  }

  return habitRow;
}

export const HabitRow = (props: {
  dashToShowId: string;
  habit: any;
  datesInView: Moment.Moment[];
  deleteHabit: (event: any) => void;
  updateHabitEntry: Function;
  toggleHabitStatus: any;
}) => {
  const {
    dashToShowId,
    habit,
    datesInView,
    deleteHabit,
    updateHabitEntry,
    toggleHabitStatus,
  } = props;
  const history = useHistory();

  const entryMap: {
    [key: string]: HabitEntry;
  } = {};
  // NEXT TWO LOOPS ARE TO ORDER THE ENTRIES
  if (habit.habitEntries) {
    const heKeys = Object.keys(habit.habitEntries);
    for (let i = 0; i < heKeys.length; i++) {
      const entry = habit.habitEntries[heKeys[i]];
      // Sometimes there are no dates on the habitEntry record
      if (entry.date && !isNaN(entry.date)) {
        const d: Moment.Moment = Moment.unix(entry.date);
        // add previousHabitId
        entryMap[d.format(DATE_FORMAT)] = Object.assign(entry, {
          previousHabitEntryId: i > 0 ? heKeys[i - 1] : 0,
        });
      }
    }
  }

  const entriesToShow = datesInView.map((date) => {
    const dateIdx = date.format(DATE_FORMAT);
    if (entryMap.hasOwnProperty(dateIdx)) {
      return entryMap[dateIdx];
    } else {
      return {
        id: generatePushID(),
        // Set date as the index used from datesInView
        date: Moment(dateIdx, DATE_FORMAT).unix(),
        message: "",
        result: "",
      };
    }
  });

  const coloredHabitCells = colorHabitCells(entriesToShow, updateHabitEntry);
  return (
    <>
      <Col className="label-col" xs={24} sm={24} md={12} lg={COL_LABEL_SIZE}>
        <Row justify="space-between" align="middle">
          <Switch
            defaultChecked
            onChange={toggleHabitStatus}
            size="small"
            checked={habit.active}
            style={{ marginRight: "0.5rem" }}
          />{" "}
          <span
            onClick={() => history.push(`/d/${dashToShowId}/h/${habit.id}`)}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>{habit.prompt}</strong>
            <InfoCircleOutlined style={{ paddingLeft: "0.5rem" }} />
          </span>{" "}
        </Row>
        <span style={{ cursor: "pointer" }} onClick={deleteHabit}>
          <DeleteTwoTone />
        </span>
      </Col>
      {coloredHabitCells}
    </>
  );
};
