import "./App.css";

import * as React from "react";

import { Layout, Menu, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import Authentication from "./firebase/Authentication";
import { Card } from "antd";
import Configure from "./Configure";
import Dashboard from "./Dashboard";
import HabitPage from "./HabitPage";
import Login from "./Login";
import { Provider } from "react-redux";
import Setup from "./Setup";
import { createStore } from "redux";
import rootReducer from "./store/reducer";

const { Sider } = Layout;
const store = createStore(rootReducer);

const auth = Authentication.getAuthentication();

const useAuth = () => {
  const [state, setState] = React.useState(() => {
    const user = auth.fb.currentUser;
    return { initializing: !user, user };
  });

  function onChange(user: any) {
    setState({ initializing: false, user });
  }

  React.useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.fb.onAuthStateChanged(onChange);
    // Unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return state;
};

const App: React.FC = () => {
  let location = useLocation();
  const { initializing, user } = useAuth();

  if (initializing) {
    return (
      <div className="site-container">
        <Card
          bordered={false}
          style={{
            width: "100%",
            paddingBottom: "1rem",
            height: "100%",
            textAlign: "center",
          }}
        >
          <Card bordered={false} style={{ width: "100%", height: "100%" }}>
            <Spin size="large" />
          </Card>
        </Card>
      </div>
    );
  }

  return (
    <Provider store={store}>
      {user ? (
        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={150}
          >
            <div className="logo">Daybyday.</div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
            >
              <Menu.Item key="/me">
                <Link to="/me">
                  <UserOutlined />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/configure">
                <Link to="/configure">
                  <VideoCameraOutlined />
                  <span className="nav-text">Configure</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            <div className="site-container">
              <Card
                bordered={false}
                style={{
                  width: "100%",
                  paddingBottom: "1rem",
                  height: "100%",
                  minHeight: "90vh",
                }}
              >
                <Switch>
                  <Route path="/configure">
                    <Configure user={user} fbAuth={auth.fb}></Configure>
                  </Route>
                  <Route path="/setup">
                    <Setup user={user} />
                  </Route>
                  <Route exact path="/d/:dashId/h/:habitId">
                    <HabitPage user={user} />
                  </Route>
                  <Route path={["/", "/me", "/d/:dashId"]} exact>
                    <Dashboard user={user} fbAuth={auth.fb}></Dashboard>
                  </Route>
                </Switch>
              </Card>
            </div>
          </Layout>
        </Layout>
      ) : (
        <Login fbAuth={auth.fb}></Login>
      )}
    </Provider>
  );
};

export default App;
