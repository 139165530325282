import * as React from "react";

import { Col } from "antd";
import { Divider } from "antd";
import Moment from "moment";
//@ts-ignore
import jStat from "jstat";

function formatNum(n: any) {
  return parseFloat(n).toFixed(2);
}

function getMaxStreak(arr: number[]) {
  let count = 0; //intitialize count
  let result = 0; //initialize max

  for (let i = 0; i < arr.length; i++) {
    // Reset count when 0 is found
    if (arr[i] === 0) {
      count = 0;
    }

    // If 1 is found, increment count
    // and update result if count becomes
    // more.
    else {
      count++; //increase count
      result = Math.max(result, count);
    }
  }

  return result;
}

function formatHabitEntries(currHabitEntries: any[]) {
  // Dedupe and sort HabitEntries
  const entriesByDay: any = {};

  for (let entry of currHabitEntries) {
    entriesByDay[Moment.unix(entry.date).format("YYYY-MM-DD")] = entry;
  }

  const days = Object.keys(entriesByDay).sort();
  const final = [];
  for (let day of days) {
    final.push(entriesByDay[day]);
  }
  return final;
}

const HabitAnalysis: React.FC<any> = (props: {
  habit: any;
  allHabits: any;
}) => {
  const { habit, allHabits } = props;

  let formattedHabitEntries: any = [];
  let sortedHabitValueEntries: any = [];
  let sortedHabitBoolEntries: any = [];
  let correlations: any = {};

  if (habit) {
    const currHabitEntries = Object.values(habit.habitEntries);
    formattedHabitEntries = formatHabitEntries(currHabitEntries);

    if (habit.type === "metric") {
      sortedHabitValueEntries = formattedHabitEntries.map((he: any) =>
        parseFloat(he.message)
      );
    } else {
      sortedHabitValueEntries = formattedHabitEntries.map(
        (he: any) => he.message
      );
    }

    sortedHabitBoolEntries = formattedHabitEntries.map((he: any) =>
      he.result === "success" ? 1 : 0
    );

    const userHabits: any = Object.values(allHabits);
    if (userHabits.length) {
      for (let habit of userHabits) {
        if (habit.habitEntries) {
          const userHEEntries: any = Object.values(habit.habitEntries);
          const sortedHabitBoolArr = formatHabitEntries(
            userHEEntries
          ).map((he: any) => (he.result === "success" ? 1 : 0));
          correlations[habit.prompt] = sortedHabitBoolArr;
        }
      }
    }
  }

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: "#333", fontWeight: "normal" }}
      >
        Analysis
      </Divider>
      {habit.type === "metric" ? (
        <>
          <p>
            <strong>Mean: </strong>
            {formatNum(jStat.mean(sortedHabitValueEntries))}
          </p>
          <p>
            <strong>Min: </strong>
            {formatNum(jStat.min(sortedHabitValueEntries))}
          </p>
          <p>
            <strong>Max: </strong>
            {formatNum(jStat.max(sortedHabitValueEntries))}
          </p>
          <p>
            <strong>Standard Deviation: </strong>
            {formatNum(jStat.stdev(sortedHabitValueEntries))}
          </p>
        </>
      ) : (
        <p>
          Longest streak is {getMaxStreak(sortedHabitBoolEntries)} days in a
          row.
        </p>
      )}

      <Divider
        orientation="left"
        style={{ color: "#333", fontWeight: "normal" }}
      >
        Correlations
      </Divider>
      {Object.values(correlations).map((arrToCompare: any, idx: number) => {
        return (
          <p>
            <strong>{Object.keys(correlations)[idx]}</strong>:{" "}
            {formatNum(
              jStat.corrcoeff(
                arrToCompare.slice(
                  -Math.max(
                    Math.min(
                      arrToCompare.length,
                      sortedHabitBoolEntries.length
                    ),
                    0
                  )
                ),
                sortedHabitBoolEntries.slice(
                  -Math.max(
                    Math.min(
                      arrToCompare.length,
                      sortedHabitBoolEntries.length
                    ),
                    0
                  )
                )
              )
            )}
          </p>
        );
      })}
    </>
  );
};

export default HabitAnalysis;
