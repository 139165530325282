import * as React from "react";

import { Button, Input, Row } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { HabitEntry } from "./models";

const { TextArea } = Input;

type ToolTipMenuProps = {
  habitEntry: HabitEntry;
  updateHabitEntry: any;
};

const TooltipMenu: React.FC<ToolTipMenuProps> = ({
  habitEntry,
  updateHabitEntry
}) => {
  const [editing, setEditing]: [any, any] = React.useState(false);
  const [msg, setMsg]: [string, any] = React.useState(habitEntry.message);

  const updateEntry = () => {
    updateHabitEntry(habitEntry, msg);
    setEditing(!editing);
  };

  const handleKeyUp = function(e: any) {
    if (e.key === "Enter") {
      updateEntry();
    }
  };

  return editing ? (
    <Row style={{ alignItems: "center" }}>
      <TextArea
        rows={2}
        placeholder="Enter new response"
        value={msg}
        onChange={e => setMsg(e.target.value)}
        onKeyUp={handleKeyUp}
      />{" "}
      <Button
        type="default"
        htmlType="submit"
        shape="round"
        size={"small"}
        onClick={() => updateEntry()}
      >
        Submit
      </Button>
    </Row>
  ) : (
    <span onClick={() => setEditing(!editing)}>
      Your response: "{habitEntry.message}" <EditOutlined />
    </span>
  );
};

export default TooltipMenu;
