import * as React from "react";

import { Col, Row } from "antd";
import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";

import { COL_LABEL_SIZE } from "./helpers/constants";
import { DATE_FORMAT } from "./helpers/constants";
import Moment from "moment";

export default function HeaderRow(props: {
  datesInView: Moment.Moment[];
  goBack: any;
  goForward: any;
}) {
  const { datesInView, goBack, goForward } = props;
  const dates: object[] = datesInView.map((d) => (
    <Col xs={6} sm={6} md={3} lg={2}>
      <strong>{d.format(DATE_FORMAT)}</strong>
    </Col>
  ));

  return (
    <Row justify="start" style={{ backgroundColor: "#fff" }}>
      <Col xs={24} sm={24} md={12} lg={COL_LABEL_SIZE}>
        <Row justify="end" align="middle" style={{ height: "100%" }}>
          <LeftCircleTwoTone
            style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
            onClick={() => goBack()}
          />
          <RightCircleTwoTone
            style={{ fontSize: "1.5rem", marginLeft: "0.5rem" }}
            onClick={() => goForward()}
          />
        </Row>
      </Col>
      {dates}
    </Row>
  );
}
