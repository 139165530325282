import * as React from "react";

import { Avatar, Col, List, Skeleton } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Divider, Tabs } from "antd";

import Database from "./firebase/Database";
import Moment from "moment";

const { TabPane } = Tabs;

function entriesGroupedByDate(entries: any) {
  return entries.reduce(function (val: any, obj: any) {
    let month = Moment.unix(obj["date"]).format("MMMM");
    (val[month] = val[month] || []).push(obj);
    return val;
  }, {});
}

const HabitRecords: React.FC<any> = (props: {
  sortedHabitEntries: any;
  dashId: string;
  habitId: string;
}) => {
  const { sortedHabitEntries, dashId, habitId } = props;
  const [habitEntries, setHabitEntries] = React.useState(sortedHabitEntries);

  React.useEffect(() => {
    setHabitEntries(sortedHabitEntries);
  }, [sortedHabitEntries]);

  const groupedHabitEntries = entriesGroupedByDate(habitEntries);

  console.log(dashId, habitId);

  function deleteHabitEntry(habitId: string, habitEntryId: string) {
    console.log(arguments);
    const db = Database.getDatabase();
    console.log(groupedHabitEntries);
    db.deleteHabitEntry(dashId, habitId, habitEntryId).then(() => {
      // Inefficient, improve
      const newHabitEntries = Array.from(
        habitEntries.filter((el: any) => el.id !== habitEntryId)
      );
      setHabitEntries(newHabitEntries);
    });
  }

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: "#333", fontWeight: "normal" }}
      >
        Your Responses
      </Divider>
      <Tabs defaultActiveKey="1" tabPosition={"left"}>
        {Object.keys(groupedHabitEntries).map((month: any) => (
          <TabPane tab={month} key={month}>
            <List
              className="demo-loadmore-list"
              loading={false} // initLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={groupedHabitEntries[month]}
              renderItem={(item: any) => (
                <List.Item
                  actions={[
                    <span onClick={() => deleteHabitEntry(habitId, item.id)}>
                      Delete
                    </span>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={false} active>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{ verticalAlign: "middle" }}
                          size="large"
                        >
                          <h2>{Moment.unix(item.date).format("D")}</h2>
                        </Avatar>
                      }
                      title={
                        item.result === "success" ? (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        ) : (
                          <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        )
                      }
                      description={item.message}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default HabitRecords;
