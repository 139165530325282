import * as React from "react";

import { Col, Popover } from "antd";

import { COL_CELL_SIZE } from "./helpers/constants";
import TooltipMenu from "./TooltipMenu";

export default function HabitCell(props: {
  habitEntry: any;
  updateHabitEntry: any;
  cellColor: any;
  cellIcon: any;
}) {
  const { habitEntry, updateHabitEntry, cellColor, cellIcon } = props;

  const [popoverVisible, setPopoverVisible] = React.useState(false);

  return (
    <Popover
      content={
        <TooltipMenu
          habitEntry={habitEntry}
          updateHabitEntry={updateHabitEntry}
        />
      }
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={() => setPopoverVisible(!popoverVisible)}
      title={habitEntry.result === "success" ? "Successful" : "Miss"}
    >
      <Col
        className="cell"
        xs={6}
        sm={6}
        md={3}
        lg={COL_CELL_SIZE}
        style={{ backgroundColor: cellColor }}
      >
        {cellIcon}
      </Col>
    </Popover>
  );
}
