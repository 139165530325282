import * as firebase from "firebase";

import { Habit, HabitEntry } from "../models";

import Moment from "moment";
import { generateNewHabitEntry } from "../helpers/utils";
import { generatePushID } from "../helpers/utils";

/**
 * The Database class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class Database {
  private static instance: Database;
  private databaseRef: firebase.database.Reference;
  private habitsRef: firebase.database.Reference;
  private habitEntriesRef: firebase.database.Reference;
  private schedulesRef: firebase.database.Reference;
  private profilesRef: firebase.database.Reference;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    const config = {
      apiKey: "AIzaSyCXH_McsBh6UnWY7odYbHmoIQLdiquFhbo",
      authDomain: "friends-with-jake.firebaseapp.com",
      databaseURL: "https://friends-with-jake.firebaseio.com",
      projectId: "friends-with-jake",
      storageBucket: "friends-with-jake.appspot.com",
      messagingSenderId: "35550268808",
      appId: "1:35550268808:web:a611ff384605557dca7e3d",
      measurementId: "G-VRNW2Z87YS",
    };

    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }

    this.databaseRef = firebase.database().ref();
    this.habitsRef = this.databaseRef.child("habits");
    this.habitEntriesRef = this.databaseRef.child("habitEntries");
    this.schedulesRef = this.databaseRef.child("schedules");
    this.profilesRef = this.databaseRef.child("profiles");
  }

  /**
   * The static method that controls the access to the singleton Database instance.
   *
   * This implementation let you subclass the Database class while keeping
   * just one instance of each subclass around.
   */
  public static getDatabase(): Database {
    if (!Database.instance) {
      Database.instance = new Database();
    }

    return Database.instance;
  }

  /**
   * Get user schedules
   */
  public async getUserSchedules(userId: string) {
    const snapshot = await this.profilesRef
      .child(userId)
      .child("schedules")
      .once("value");
    return snapshot.val();
    // NOTE: Uncomment the bottom to backfill schedule indexes in profile
    // const snapshot = await this.schedulesRef.once("value");
    // const allSchedules = snapshot.val();
    // const allSchedEntries: any = Object.entries(allSchedules);
    // for (let [hour, usersObj] of allSchedEntries) {
    //   console.log(hour, usersObj);
    //   for (let user of Object.keys(usersObj)) {
    //     this.addUserSchedule(hour, user);
    //   }
    // }
  }

  /**
   * Add user schedule
   */
  public async addUserSchedule(hour: string, userId: string) {
    await this.deleteUserSchedules(userId);

    const schedRefTemp: any = {};
    schedRefTemp[userId] = true;
    await this.schedulesRef.child(hour).update(schedRefTemp);

    const schedulesIdx: any = {};
    schedulesIdx[hour] = true;
    return await this.profilesRef
      .child(userId)
      .child("schedules")
      .update(schedulesIdx);
  }

  /**
   * Get user schedules
   */
  public async deleteUserSchedules(userId: string) {
    const userSchedulesSnap = await this.profilesRef
      .child(userId)
      .child("schedules")
      .once("value");
    const schedules = userSchedulesSnap.val();

    if (schedules) {
      const hours = Object.keys(schedules);
      for (let hour of hours) {
        await this.deleteUserSchedule(hour, userId);
      }

      await this.profilesRef.child(userId).child("schedules").remove();
    }
  }

  /**
   * Delete a single user schedule
   */
  public async deleteUserSchedule(hour: string, userId: string) {
    await this.schedulesRef.child(hour).child(userId).remove();

    return await this.profilesRef
      .child(userId)
      .child("schedules")
      .child(hour)
      .remove();
  }

  /**
   * Get user habits
   */
  public async getUserHabits(userId: string) {
    const filter = await this.habitsRef.child(userId);
    const snapshot = await filter.once("value");
    return snapshot.val();
  }

  /**
   * Toggle habit status
   */
  public async toggleHabitStatus(
    habitId: string,
    active: boolean,
    userId: string
  ) {
    await this.habitsRef
      .child(userId)
      .child(habitId)
      .update({ active: active });
  }

  /**
   * Create habit entries
   */
  public async createUserHabit(habit: Habit, userId: string) {
    return await this.habitsRef.child(userId).push(Object.assign(habit));
  }

  /**
   * Update user habit
   */
  public async updateHabit(habitId: string, userId: string, payload: any) {
    return await this.habitsRef.child(userId).child(habitId).update(payload);
  }

  /**
   * Delete user habit
   */
  public async deleteHabit(habitId: string, userId: string) {
    return await this.habitsRef.child(userId).child(habitId).remove();
  }

  /**
   * Get user habitEntries
   */
  public async getHabitEntries(userId: string) {
    const filter = await this.habitEntriesRef.child(userId);
    const snapshot = await filter.once("value");
    return snapshot.val();
  }

  /**
   * Create user habitEntry
   */
  // NEED TO UPDATE WITH USER ID
  public async createHabitEntry(habitId: string, habitEntry: HabitEntry) {
    return await this.habitEntriesRef.push(
      Object.assign(habitEntry, { habitId: habitId })
    );
  }

  /**
   * Edit habitEntry
   */
  public async editHabitEntry(updateData: {
    habitKey: string;
    habit: Habit;
    habitEntry: HabitEntry;
    newResponse: any;
    userId: string;
  }) {
    const { habitKey, userId } = updateData;

    const newHabitEntry: any = await generateNewHabitEntry(
      updateData,
      this.databaseRef
    );

    await this.habitEntriesRef
      .child(userId)
      .child(habitKey)
      .child(newHabitEntry.id)
      .set(newHabitEntry);

    return newHabitEntry;
  }

  /**
   * Get user habitEntries
   */
  public async deleteHabitEntry(
    userId: string,
    habitId: string,
    habitEntryId: string
  ) {
    return await this.habitEntriesRef
      .child(userId)
      .child(habitId)
      .child(habitEntryId)
      .remove();
  }

  /**
   * Populate DB with sample data
   */
  // public async createSampleData(
  //   userId: string,
  //   habits: Habit[],
  //   habitEntries: HabitEntry[]
  // ) {
  //   for (let habit of Object.habits) {
  //     this.createUserHabit(userId, habit);

  //     for (let habitEntry of habitEntries) {
  //       this.createHabitEntry(habit.id, habitEntry);
  //     }
  //   }
  // }

  public async forceSendSms(userId: string) {
    var forceSend = firebase.functions().httpsCallable("forceSendSms");

    forceSend({ userId: userId }).then(function (result) {
      // Read result of the Cloud Function.
      console.log(result);
      // ...
    });
  }
}

const habit: Habit = {
  prompt: "Did you meditate today?",
  active: true,
  createdAt: Moment().unix(),
  type: "action",
};

const habitEntries: HabitEntry[] = [];
for (let i = 0; i < 30; i++) {
  const option = [
    { message: "yes", result: "success" },
    { message: "no", result: "failure" },
  ][Math.floor(Math.random() * 2)];

  habitEntries.push({
    id: "habitEntry123",
    habitId: "habit123",
    date: Moment().subtract(i, "days").unix(),
    message: option.message,
    result: option.result,
  });
}

export default Database;
