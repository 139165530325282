const setHabitEntries = (userHabitEntries: any) => {
  return {
    type: "SET_HABIT_ENTRIES",
    payload: userHabitEntries,
  };
};

const setSchedules = (schedules: any[]) => {
  return {
    type: "SET_SCHEDULES",
    payload: schedules,
  };
};

export default {
  setHabitEntries,
  setSchedules,
};
