import * as React from "react";

function rootReducer(state = { userHabits: {}, schedules: [] }, action: any) {
  switch (action.type) {
    case "SET_HABIT_ENTRIES":
      return {
        userHabits: { ...state.userHabits, ...action.payload },
        schedules: state.schedules,
      };
    case "SET_SCHEDULES":
      return {
        userHabits: state.userHabits,
        schedules: action.payload,
      };
    default:
      return { userHabits: {}, schedules: [] };
  }
}

export default rootReducer;
