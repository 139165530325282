import * as React from "react";

import { Divider, Input, Select } from "antd";

import { Col } from "antd";
import Database from "./firebase/Database";
import { EditTwoTone } from "@ant-design/icons";
import Moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

const { Option } = Select;

const HabitDescription: React.FC<any> = (props: { habit: any; user: any }) => {
  const { habit, user } = props;
  const [editingPrompt, setEditingPrompt] = React.useState(false);
  const [prompt, setPrompt] = React.useState(habit.prompt);
  const [habitType, setType] = React.useState(habit.type);

  React.useEffect(() => {
    setPrompt(habit.prompt);
    setType(habit.type);
  }, [habit.prompt, habit.type]);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Divider
        orientation="left"
        style={{ color: "#333", fontWeight: "normal" }}
      >
        Habit Attributes
      </Divider>
      <p>
        <strong>Prompt:</strong>
      </p>
      <p>
        {editingPrompt ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (editingPrompt) {
                const db = Database.getDatabase();
                db.updateHabit(habit.id, user.uid, { prompt: prompt }).then(
                  () => {
                    setEditingPrompt(false);
                  }
                );
              }
            }}
          >
            <Input
              defaultValue={prompt}
              onChange={(e) => setPrompt(e.currentTarget.value)}
            />
          </OutsideClickHandler>
        ) : (
          <span onClick={() => setEditingPrompt(true)}>
            {prompt} <EditTwoTone />
          </span>
        )}
      </p>
      <p>
        <strong>Type:</strong>
      </p>
      <p>
        <Select
          value={habitType}
          style={{ width: 120 }}
          onChange={(val) => {
            const db = Database.getDatabase();
            db.updateHabit(habit.id, user.uid, { type: val }).then(() => {
              setType(val);
            });
          }}
        >
          <Option value="action">Action</Option>
          <Option value="metric">Metric</Option>
        </Select>
      </p>
      <p>
        <strong>Created At:</strong>
      </p>
      <p>{Moment.unix(habit.createdAt).format("MMMM Do, YYYY h:mm a")}</p>
    </div>
  );
};

export default HabitDescription;
