import "./Dashboard.css";

import * as React from "react";
import * as firebase from "firebase";

import { Card, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ACTIONS from "./store/actions";
import Database from "./firebase/Database";
import { HabitEntry } from "./models";
import { HabitRow } from "./HabitRow";
import HeaderRow from "./HeaderRow";
import { IS_MOBILE } from "./helpers/constants";
import Moment from "moment";
import { formatHabitsForDisplay } from "./helpers/utils";
import { useSwipeable } from "react-swipeable";

type DashboardProps = {
  user: any | firebase.User | null;
  fbAuth: any;
};

function generateDatesToShow(numDatesToShow: number, daysBack: number) {
  let startingDates: Moment.Moment[] = [];
  for (let i = daysBack; i < daysBack + numDatesToShow; i++) {
    startingDates.unshift(Moment().subtract(i, "day"));
  }
  return startingDates;
}

const Dashboard: React.FC<DashboardProps> = ({ user, fbAuth }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashId } = useParams();
  const habitsByUser = useSelector((state: any) => state.userHabits);
  const [daysBack, setDaysBack] = React.useState(0);

  const [numDatesToShow, setNumDatesToShow] = React.useState(IS_MOBILE ? 4 : 7);

  const datesInView = generateDatesToShow(numDatesToShow, daysBack);

  function resize() {
    if (IS_MOBILE) {
      setNumDatesToShow(4);
    } else {
      setNumDatesToShow(7);
    }
  }
  window.addEventListener("resize", resize);

  const dashToShowId = dashId ? dashId : user.uid;
  React.useEffect(() => {
    const db = Database.getDatabase();

    let habits: any;
    let habitEntries: any;

    if (user && !habitsByUser[dashToShowId]) {
      habits = db.getUserHabits(dashToShowId);
      habitEntries = db.getHabitEntries(dashToShowId);

      Promise.all([habits, habitEntries]).then((results: [any, any]) => {
        const [habits, habitEntries] = results;
        if (habits) {
          const formattedHabits = formatHabitsForDisplay(habits, habitEntries);

          const obj: any = {};
          obj[dashToShowId] = formattedHabits;

          dispatch(ACTIONS.setHabitEntries(obj));
        } else {
          return history.push("/setup");
        }
      });
    }
  }, [user, dashId]);

  function goBack() {
    setDaysBack(daysBack + numDatesToShow);
  }

  function goForward() {
    setDaysBack(daysBack - numDatesToShow);
  }

  const toggleHabitStatus = (habitId: string, active: boolean) => {
    if (user) {
      const db = Database.getDatabase();
      db.toggleHabitStatus(habitId, active, user.uid).then(() => {
        const newUserHabits = Object.assign({}, habitsByUser);
        newUserHabits[user.uid][habitId]["active"] = active;
        dispatch(ACTIONS.setHabitEntries(newUserHabits));
      });
    }
  };

  const deleteHabit = (habitId: string) => {
    if (user) {
      const db = Database.getDatabase();
      db.deleteHabit(habitId, user.uid).then(() => {
        const newUserHabits = Object.assign({}, habitsByUser);
        delete newUserHabits[user.uid][habitId];
        dispatch(ACTIONS.setHabitEntries(newUserHabits));
      });
    }
  };

  const habitsRows = [];
  for (let habitKey in habitsByUser[dashToShowId]) {
    const habit = habitsByUser[dashToShowId][habitKey];
    const coloredEntries = (
      <HabitRow
        dashToShowId={dashToShowId}
        habit={habit}
        datesInView={datesInView}
        deleteHabit={() => deleteHabit(habitKey)}
        updateHabitEntry={async (
          habitEntry: HabitEntry,
          newResponse: string
        ) => {
          if (user) {
            const db = Database.getDatabase();
            const updatedOrAdded = await db.editHabitEntry({
              habitKey,
              habit,
              habitEntry,
              newResponse,
              userId: user.uid,
            });

            if (updatedOrAdded) {
              const newUserHabits = Object.assign({}, habitsByUser);
              newUserHabits[user.uid][habitKey].habitEntries[
                updatedOrAdded.id
              ] = updatedOrAdded;
              dispatch(ACTIONS.setHabitEntries(newUserHabits));
            }
          }
        }}
        // the ant design event is weird, it goes (value, event)
        toggleHabitStatus={(active: any) => toggleHabitStatus(habitKey, active)}
      />
    );
    habitsRows.push(coloredEntries);
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData: any) => {
      goForward();
    },
    onSwipedRight: (eventData: any) => {
      goBack();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...swipeHandlers}>
      <Card
        className="dashboard"
        bordered={false}
        style={{ width: "100%", height: "100%" }}
      >
        <HeaderRow
          datesInView={datesInView}
          goBack={goBack}
          goForward={goForward}
        />
        {habitsRows.map((coloredEntries) => (
          <Row justify="start" className="habit-row">
            {coloredEntries}
          </Row>
        ))}
      </Card>
    </div>
  );
};

export default Dashboard;
