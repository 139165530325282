import * as React from "react";
import * as firebase from "firebase";

import { Button, Card, Col, Divider, Form, Input, List, Row } from "antd";
import { DeleteTwoTone, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import ACTIONS from "./store/actions";
import Database from "./firebase/Database";
import Moment from "moment-timezone";
import { Select } from "antd";
import { timeOptions } from "./helpers/utils";
import { useHistory } from "react-router-dom";

type SettingsProps = {
  user: firebase.User;
  fbAuth: any;
};

const Configure: React.FC<SettingsProps> = ({ user, fbAuth }) => {
  const [newHabitPrompt, setNewHabitPrompt] = React.useState("");
  const [newHabitSuccessKeywords, setNewHabitSuccessKeywords] =
    React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const userHabits = useSelector((state: any) => state.userHabits);
  const schedules = useSelector((state: any) => state.schedules);

  React.useEffect(() => {
    if (user && !schedules.length) {
      const db = Database.getDatabase();
      const schedules = db
        .getUserSchedules(user.uid)
        .then((scheduleResults) => {
          const schedObjs = Object.keys(scheduleResults).map((utcHour) => {
            return {
              utcHour: utcHour,
              display: Moment.utc(utcHour, "HH")
                .tz(Moment.tz.guess())
                .format("hh:mm A zz"),
            };
          });
          dispatch(ACTIONS.setSchedules(schedObjs));
        });
    }
  }, [user]);

  let history = useHistory();
  const logOut = () => {
    fbAuth.signOut().then(() => {
      return history.push("/");
    });
  };

  const onAddHabit = (values: any) => {
    if (user) {
      const db = Database.getDatabase();
      db.createUserHabit(
        {
          prompt: values.prompt,
          active: true,
          createdAt: Moment().unix(),
          type: "action",
        },
        user.uid
      ).then(() => {
        setSubmitted(true);
      });
    }
  };

  // function clickForceSendSms() {
  //   if (user) {
  //     const db = Database.getDatabase();
  //     db.forceSendSms(user.uid);
  //   }
  // }

  const deleteSchedule: any = (schedHr: string) => {
    if (user) {
      const db = Database.getDatabase();
      db.deleteUserSchedule(schedHr, user.uid).then(() => {
        const newUserScheds: any = Array.from(schedules);
        newUserScheds.splice(
          newUserScheds.findIndex((s: any) => s.utcHour === schedHr),
          1
        );
        dispatch(ACTIONS.setSchedules(newUserScheds));
      });
    }
  };

  async function handleChange(hour: any) {
    if (user) {
      const db = Database.getDatabase();
      await db.addUserSchedule(hour, user.uid).then(() => {
        const newUserScheds: any = [
          {
            utcHour: hour,
            display: Moment.utc(hour, "HH")
              .tz(Moment.tz.guess())
              .format("hh:mm A zz"),
          },
        ];
        dispatch(ACTIONS.setSchedules(newUserScheds));
      });
    }
  }

  return (
    <Card bordered={false} style={{ width: "100%" }}>
      <Row>
        <Col lg={12} sm={24}>
          <Divider
            orientation="left"
            style={{ color: "#333", fontWeight: "normal" }}
          >
            Your Scheduled Message Time
          </Divider>
          <List
            itemLayout="horizontal"
            dataSource={schedules}
            style={{ marginBottom: "1rem" }}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                  title={item.display}
                  description={`${Moment.utc(item.utcHour, "HH").format(
                    "HH:mm A zz"
                  )} in UTC`}
                />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => deleteSchedule(item.utcHour)}
                >
                  <DeleteTwoTone />
                </span>
              </List.Item>
            )}
          />
          <p>Choose the time slot that you'd like to hear from us.</p>
          <p>
            At that time every day, you'll get a prompt for each habit or metric
            that you're tracking. We'll let you know when we're wrapped up. If
            at any point you'd like to stop receiving texts from us, simply text
            STOP.
          </p>
          <div style={{ textAlign: "center" }}>
            <p>
              <Select
                placeholder="Choose a time"
                style={{ width: "80%" }}
                onChange={handleChange}
              >
                {timeOptions()}
              </Select>
            </p>
          </div>
        </Col>
        <Col lg={12} sm={24}>
          <Divider
            orientation="left"
            style={{ color: "#333", fontWeight: "normal" }}
          >
            Settings
          </Divider>
          <p>
            <strong>Your Phone Number:</strong>
            <br />
            {user.phoneNumber}
          </p>
          {userHabits[user.uid] ? (
            <p>
              <strong>Your Plan:</strong>
              <br />
              {`${
                Object.keys(userHabits[user.uid]).length
              }/10 habits being tracked`}
            </p>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={24} lg={12}>
          <Divider
            orientation="left"
            style={{ color: "#333", fontWeight: "normal" }}
          >
            Actions
          </Divider>
          <Button
            type="default"
            shape="round"
            icon={<DownloadOutlined />}
            size={"small"}
            onClick={logOut}
          >
            Sign Out
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Configure;
